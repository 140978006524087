<template>
  <v-container>
    <v-row>
      <v-col>
        <base-material-card>
          <tattooers-form
            :title="$t('new', {name: $tc('tattooer')})"
            :tattooer="tattooer"
            @validated="saveTattooer()"
          ></tattooers-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/constants";
export default {
  name: "TattooerNew",
  components: { TattooersForm: () => import("@/components/tattooer/Form") },
  data() {
    return {
      tattooer: {
        user: { role_id: constants.roles.TATTOOER },
        social_networks: {},
        token_payment: [
          { size: "Big", price: null },
          { size: "Medium", price: null },
          { size: "Small", price: null },
        ],
      },
    };
  },
  methods: {
    ...mapActions("tattooers", ["addTattooer"]),
    saveTattooer() {
      this.addTattooer({ tattooer: this.tattooer }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("tattooer_create_success"), '', 'success');
        }
        this.$router.back();
      });
    },
  },
};
</script>